require('./bootstrap');
import 'htmx.org';
import './htmx.js';
import hljs from 'highlight.js';

document.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll('pre code').forEach((block) => {
        hljs.highlightElement(block);
        document.querySelector('#resources-list div.bg-card').click();
    });

    const testsButton = document.querySelector('#tests-button');
    testsButton.addEventListener('click', (event) => {
        const testsParamsResponse = document.querySelector('#tests-params-response');
        const resourceParam = document.querySelector('#tests-resources').value;
        const params = testsParamsResponse.querySelectorAll('input');
        const url = new URL(`${window.location.origin}/api/v2/${resourceParam}`);
        console.log(params);
        params.forEach((param) => {
            if (param.value) {
                url.searchParams.append(param.name, param.value);
            }
        });
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const response = document.querySelector('#tests-response');
                let content = `<pre>
                    <code class="hljs language-json rounded-md">${JSON.stringify(data, null, 4)}</code>
                </pre>`
                response.innerHTML = content;
                hljs.highlightElement(response.querySelector('pre code'));
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    });
});

// Riconfigura HTMX per evidenziare il nuovo contenuto caricato dinamicamente
document.body.addEventListener('htmx:afterSwap', (event) => {
    document.querySelectorAll('pre code').forEach((block) => {
        // se l'elemento non è stato già evidenziato, evidenzialo
        if (block.dataset.highlighted === 'yes') {
            return;
        }
        // Se il codice è JSON, formatta
        if (block.classList.contains('language-json') && block.dataset.highlighted !== 'yes') {
            try {
                const formatted = JSON.stringify(JSON.parse(block.textContent), null, 4);
                block.textContent = formatted;
            } catch (e) {
                console.error("Invalid JSON");
            }
        }
        hljs.highlightElement(block);
    });

    // reload remove row listeners after content swapping
    addRemoveRowListeners();
});

function addRemoveRowListeners() {
    const removeRowButtons = document.querySelectorAll('[data-scope="remove-row"]');
    removeRowButtons.forEach((button) => {
        button.removeEventListener('click', handleRemoveRow); // Rimuove eventuali vecchi listener per evitare duplicazioni
        button.addEventListener('click', handleRemoveRow);
    });
}